<template>
  <div class="dialog-mobile-search">
    <div class="dialog-mobile-search__head">
      <label v-on:keyup.enter="routePageSearch">
        <img src="~/assets/img/svg/common/search.svg" class="--search"/>
        <input
          v-model="search"
          type="text"
          placeholder="Введите поисковый запрос..."
        />
        <img src="~/assets/img/svg/common/close.svg" class="--close" @click="closeSearch"/>
      </label>
    </div>
    <div class="dialog-mobile-search__body">

      <template v-if="Boolean(visibleSection === 'result')">
        <section
          v-for="(section) in sectionsBody"
          class="mobile-search-section"
        >
          <div class="mobile-search-section__head">
            <div class="--title h5 semibold">
              {{ section.name }}
              <sup>{{ section.total }}</sup>
            </div>
            <div class="--link">
              <nuxt-link
                :to="`/catalogsearch/result?q=${ search }&type=${ section.sectionKey }`"
                @click="closeSearch"
              >Показать все</nuxt-link>
            </div>
          </div>
          <div class="mobile-search-section__items">
            <nuxt-link
              v-for="(item) in section.items"
              v-html="item.name || item.title"
              :to="item.url"
              @click="closeSearch"
            />
          </div>
        </section>
      </template>
      <template v-if="Boolean(visibleSection === 'loading')">
        <div class="dialog-mobile-search__message">Загрузка данных...</div>
      </template>
      <template v-if="Boolean(visibleSection === 'no-result')">
        <div class="dialog-mobile-search__message">По вашему запросу ничего не найдено</div>
      </template>
      <template v-if="Boolean(visibleSection === 'not-search' && false)">
        <div class="h2">Часто ищут</div>
        <div class="dialog-mobile-search__popular-list">
          <div
            v-for="(itemSearch) in popularSearchList"
            :key="`popularSearchListitemSearch-${ itemSearch }`"
            @click="() => startSearchFromPopular(itemSearch)"
          >{{ itemSearch }}</div>
        </div>
      </template>

    </div>
  </div>
</template>

<script setup lang="ts">
import { useMobileSearch } from '~/composables/useMobileSearch';
import type { ISectionBody, ISectionHead } from '~/types/search';
import type { ComputedRef } from 'vue';

const {
  changeOpen
} = useMobileSearch();
const {
  $api,
  $router
} = useNuxtApp();

const closeSearch = () => {
  changeOpen(false);
}

const search: Ref<string> = ref('');
const isNotResult: Ref<boolean> = ref(false);
const isLoadSearch: Ref<boolean> = ref(false);
const sectionsBody: Ref<ISectionBody[]> = ref([]);

const visibleSection: ComputedRef<string> = computed(() => {
  if (!isLoadSearch.value && !isNotResult.value && sectionsBody.value.length > 0) {
    return 'result'
  }
  if (isLoadSearch.value) {
    return 'loading'
  }
  if (!isLoadSearch.value && sectionsBody.value.length <= 0 && search.value.length <= 2) {
    return 'not-search'
  }
  if (!isLoadSearch.value && isNotResult.value) {
    return 'no-result'
  }

  return 'not-search'
});

const getResult = async () => {

  const results = await $api.agent.get(`/catalog/search?query=${ search.value }`).then((res) => {
    return res?._data?.results || {}
  }).catch(() => {
    return {}
  });

  let _sectionsBody: ISectionBody[] = [];
  Object.keys((results || {})).map((resultKey: string) => {
    let resultValue = results[resultKey];
    if (resultKey === 'brands') {
      const _searchValue: string = String(search.value).toLowerCase();
      const _items = [...(resultValue?.items || [])].filter((t: any) => {
        const nameSmall: string = String(t?.name).toLowerCase();
        return Boolean(_searchValue === nameSmall)
      });
      resultValue = {
        ...resultValue,
        items: _items,
        total: _items.length
      }
    }

    if (resultValue.total > 0) {
      _sectionsBody.push({
        sectionKey: resultKey,
        isShowButtonMore: false,
        items: resultValue?.items || [],
        name: resultValue.name,
        total: resultValue.total,
        type: ''
      });
    }
  });
  if (_sectionsBody.length <= 0) {
    isNotResult.value = true;
    isLoadSearch.value = false;
    return
  }

  sectionsBody.value = _sectionsBody;
  isNotResult.value = false;
  isLoadSearch.value = false;
}

let timeOutSearch: any = null;
watch(search, async () => {
  clearTimeout(timeOutSearch);
  isLoadSearch.value = Boolean(search.value.length >= 2);
  if (Boolean(search.value.length < 2)) {
    return
  }
  timeOutSearch = setTimeout(async () => {
    await getResult();
  }, 1000);
});

const popularSearchList: ComputedRef<string[]> = computed(() => {
  return ['Кровать детская', 'Диван кровать', 'Cilek', 'Сканди', 'Кровать с мягким изголовьем']
});
const startSearchFromPopular = async (value: string) => {
  search.value = value;
}

const routePageSearch = () => {
  if ((search.value || '').length < 2) {
    return
  }
  $router.push(`/catalogsearch/result/?q=${ search.value }`);
  closeSearch();

  clearTimeout(timeOutSearch);
}
</script>

<style lang="scss">
.dialog-mobile-search {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  top: 33px; bottom: 0;
  left: 0; right: 0;
  background: white;
  padding: 12px 20px 20px;
  box-sizing: border-box;
}
.dialog-mobile-search__head {
  position: relative;
  z-index: 2;

  label {
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #B8B8B8;
    padding: 0 15px;
    box-sizing: border-box;
    position: relative;
  }
  input {
    flex: 1;
    outline: none!important;
    box-shadow: none!important;
    padding-right: 32px;
    box-sizing: border-box;

    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
  }
  .--close {
    position: absolute;
    width: 34px; height: 34px;
    right: 15px;
    object-fit: cover;
    object-position: center;
    filter: invert(61%) sepia(11%) saturate(13%) hue-rotate(31deg) brightness(97%) contrast(94%);
  }
  .--search {
    width: 28px;
    height: 28px;
    object-fit: contain;
    object-position: center;
    margin-right: 10px;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: -21px;
    right: -21px;
    bottom: -12px;
    height: 64px;
    border-radius: 0 0 24px 24px;
    border: 1px solid var(--line-color-border-smoky-white, #EBEBEB);
    border-top: initial;
    pointer-events: none;
    background: white;
  }
}
.dialog-mobile-search__body {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 36px 0 24px;
  padding-right: 16px;
  margin-right: -16px;
  box-sizing: border-box;
  overflow: auto;

  .h2 {
    margin-bottom: 24px;
  }
}

.dialog-mobile-search__message {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #989898;
}
.dialog-mobile-search__popular-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-left: -10px;
  & > * {
    padding: 14px 23px;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;

    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #000000;
  }
}

.mobile-search-section {}
.mobile-search-section__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #F4F4F4;

  .--title {
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
    color: #000;

    sup {
      font-size: 12px;
      line-height: 100%;
      margin-left: 4px;
    }
  }
  .--link {
    margin-left: 20px;
    a {
      font-size: 12px;
      font-weight: 500;
      color: #989898;
    }
  }
}
.mobile-search-section__items {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  box-sizing: border-box;

  & > * {
    padding: 8px 16px;
    box-sizing: border-box;

    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    color: #000;
  }
}
</style>
